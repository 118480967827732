import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Divider,
	Fade,
	Grid,
	Modal,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useLocation } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { CSVLink } from "react-csv";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import { useData } from "../../../contex/contex";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import dayjs from "dayjs";
import QuotationPreview from "./QuotationPreview";
import PrintIcon from "@mui/icons-material/Print";
import { reactLocalStorage } from "reactjs-localstorage";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
	overflow: "scroll",
	width: "max-content",
	height: "100%",
	minWidth: "50%",
};

const Bookings = () => {
	const [showPreview, setShowPreview] = useState(false);
	const location = useLocation();
	const [deleted, setDeleted] = useState(false);
	const [bookings, setBookings] = useState([]);
	const [done, setDone] = useState(false);
	const { token, user } = useData();
	const [submitting, setSubmitting] = useState(false);
	const [allDriverList, setAllDriverList] = useState([]);

	const [isQuoted, setIsQuoted] = useState("All");
	const handlePreview = (datas) => {
		setShowPreview(datas);
	};

	const handleClosePreview = () => {
		setShowPreview(false);
	};

	useEffect(() => {
		if (location?.search?.includes("Pending")) {
			setIsQuoted("Pending");
		} else if (location?.search?.includes("Completed")) {
			setIsQuoted("Completed");
		} else {
			setIsQuoted("All");
		}
	}, [location?.search]);

	const [seletedBooking, setSelectedBooking] = useState({});

	const [open, setOpen] = React.useState(false);
	const handleOpen = (booking) => {
		setEditPayment({
			tax: booking?.tax,
			tips: booking?.tips,
		});
		setOpen(true);
		setSelectedBooking(booking);
	};
	const handleClose = () => {
		setOpen(false);
		setSelectedBooking({});
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_PATH}/AllDrivers`,
					{
						headers: {
							Authorization: token,
						},
					},
				);

				setAllDriverList(response.data.reverse());
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.response.data.message,
				});
			}
		};
		token && fetchData();
	}, [token, submitting, deleted]);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_API_PATH}/filterbookings/${user?.userRole}/${user?.email}`,
					{
						headers: {
							Authorization: token,
						},
					},
				);

				setBookings(response.data);
				setNewList(response.data.reverse());
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: error.response.data.message,
				});
			}
		};
		token && fetchData();
	}, [done, deleted, token, user, open, showPreview]);

	const [search, setSearch] = React.useState("");
	const [newList, setNewList] = React.useState([]);
	const handleChange = (event) => {
		setSearch(event.target.value);
	};
	useEffect(() => {
		const searchs = bookings.filter(
			(booking) =>
				booking?.firstName?.toLowerCase().includes(search.toLowerCase()) ||
				booking?.lastName?.toLowerCase().includes(search.toLowerCase()) ||
				booking?.txn?.toLowerCase().includes(search.toLowerCase()) ||
				booking?.phone?.toLowerCase().includes(search.toLowerCase()) ||
				booking?.company?.toLowerCase().includes(search.toLowerCase()) ||
				booking?.rideType?.toLowerCase().includes(search.toLowerCase()),
		);
		setNewList(searchs);
	}, [bookings, search]);

	const handleDelete = async (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				setDeleted(true);
				try {
					const response = await axios.delete(
						`${process.env.REACT_APP_API_PATH}/bookings/${id}`,
						{
							headers: {
								Authorization: token,
							},
						},
					);

					if (response.status) {
						Swal.fire({
							icon: "success",
							title: "Deleted Successfully",
							showConfirmButton: false,
							timer: 1500,
						});
						setDeleted(false);
					}
				} catch (error) {
					Swal.fire({
						icon: "error",
						title: "Error",
						text: error.response.data.message,
					});
				}
			}
		});
	};

	const handleDone = async (id) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, Mark as Completed!",
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					const response = await axios.put(
						`${process.env.REACT_APP_API_PATH}/bookingsCompleted/${id}`,
						{
							position: "Completed",
						},
						{
							headers: {
								Authorization: token,
							},
						},
					);

					if (response.status) {
						Swal.fire({
							icon: "success",
							title: "That booking has been marked as Completed.",
							showConfirmButton: false,
							timer: 1500,
						});
						setDone(true);
					}
				} catch (error) {
					Swal.fire({
						icon: "error",
						title: "Error",
						text: error.response.data.message,
					});
				}
			}
		});
	};

	let count = 1;
	const headers = [
		{ label: "You Are ?", key: "forWho" },
		{ label: "Company", key: "company" },
		{ label: "First Name", key: "firstName" },
		{ label: "Last Name", key: "lastName" },
		{ label: "Email", key: "userEmail" },
		{ label: "Phone Number", key: "phone" },
		{ label: "Notes", key: "comment" },
		{ label: "Booking Time", key: "bookingTime" },
		{ label: "TXN", key: "txn" },
		{ label: "Booked By", key: "bookedBy" },
		{
			label: "Quoted?",
			key: "quotedPrice",
		},
		{
			label: "Price By Admin",
			key: "priceByAdmin",
		},
	];

	const [modalType, setModalType] = useState("Driver-Assign");
	const [paymentType, setPaymentType] = useState("");
	const [amount, setAmount] = useState("");
	const [paidFrom, setPaidFrom] = useState("");

	const handleearchRangeByBookingTime = async (time1, time2) => {
		time1 = new Date(time1) - 1;
		time2 = new Date(time2) + 1;
		const list = bookings.filter(
			(booking) =>
				new Date(booking?.bookingTime) >= new Date(time1) &&
				new Date(booking?.bookingTime) <= new Date(time2),
		);
		setNewList(list);
	};

	const [time1, setTime1] = useState("");
	const [time2, setTime2] = useState("");

	const [viewType, setViewType] = useState(
		reactLocalStorage.get("viewType") || "Payment",
	);

	const divRef = useRef(null);

	const handleDownload = () => {
		const divElement = divRef.current;

		const options = {
			scale: 2,
			logging: true,
		};

		html2canvas(divElement, options).then((canvas) => {
			const imgData = canvas.toDataURL("image/png");
			const pdf = new jsPDF("p", "mm", "a4");
			pdf.addImage(
				imgData,
				"PNG",
				0,
				0,
				pdf.internal.pageSize.width,
				pdf.internal.pageSize.height,
			);
			pdf.save("Summary.pdf");
		});
	};

	const headers2 = [
		{ label: "No", key: "count" },
		{ label: "TXN", key: "txn" },
		{ label: "Date", key: "bookingTime" },
		{ label: "Paid", key: "paidAmount" },
		{ label: "Unpaid", key: "unpaidAmount" },
		{ label: "Price", key: "price" },
	];

	const processedData = newList?.map((row, index) => ({
		count: index + 1,
		txn: row.txn,
		bookingTime: row.bookingTime,
		paidAmount:
			row?.payments?.reduce(
				(acc, payment) => acc + Number(payment.amount),
				0,
			) || 0,
		unpaidAmount:
			(Number(row.priceByAdmin) || Number(row.quotedPrice) || 0) -
			(row?.payments?.reduce(
				(acc, payment) => acc + Number(payment.amount),
				0,
			) || 0),
		price: row.priceByAdmin || row.quotedPrice || 0,
	}));

	const [selectedDate, setSelectedDate] = useState(null);

	const handleDateClick = (date) => {
		setSelectedDate(date);
	};
	const markedDates = bookings
		?.map((booking) => booking?.rides?.map((ride) => new Date(ride?.time1)))
		.filter((dates) => dates && dates.length > 0);

	const findBookingsByDate = (date) => {
		const bookings = newList.filter((booking) =>
			booking?.rides?.some((ride) =>
				dayjs(ride?.time1).isSame(new Date(date).toLocaleString(), "day"),
			),
		);
		return bookings;
	};

	const tileClassName = ({ date }) => {
		return markedDates?.some((markedDate) =>
			dayjs(markedDate).isSame(date, "day"),
		)
			? "highlighted"
			: "blocked";
	};
	const tileContent = ({ date, view }) => {
		return (
			<span
				style={{
					fontSize: "0.9rem",
				}}>
				<br />
				{markedDates?.filter((user) => dayjs(user).isSame(date, "day")).length >
					0
					? `(${markedDates?.filter((user) => dayjs(user).isSame(date, "day"))
						.length
					})`
					: null}
			</span>
		);
	};

	const [editPayment, setEditPayment] = useState({
		tax: 0,
		tips: 0,
	});

	return (
		<Container sx={{ mt: 2, minHeight: "100vh" }}>
			<Grid>
				<Typography sx={{ fontWeight: "bold" }} variant='h4' component='div'>
					Booking Requests
				</Typography>
				<Box>
					<Button
						className='buttonColor'
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor: "transparent",
							borderRadius: "25px",
							m: 0.5,
						}}
						variant='contained'>
						<CSVLink
							filename={"bookings.csv"}
							data={processedData}
							headers={headers2}
							className='buttonColor'
							style={{
								textDecoration: "none",
								color: "white",
							}}>
							Download CSV File
						</CSVLink>
					</Button>
				</Box>
				<Grid container spacing={2}>
					<Grid item md={6} xs={12} sx={{ mx: "auto" }}>
						<TextField
							sx={{ width: "100%" }}
							id='outlined-name'
							placeholder='Search by Name / TXN / Phone ? Company'
							value={search}
							onChange={handleChange}
							size='small'
						/>
					</Grid>
				</Grid>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<Button
						className='buttonColor'
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor:
								viewType === "Payment" ? "green !important" : "transparent",
							borderRadius: "25px",
							m: 0.5,
						}}
						variant='contained'
						onClick={() => {
							reactLocalStorage.set("viewType", "Payment");
							setViewType("Payment");
						}}>
						Payment View
					</Button>
					<Button
						className='buttonColor'
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor:
								viewType === "Detailed" ? "green !important" : "transparent",
							borderRadius: "25px",
							m: 0.5,
						}}
						variant='contained'
						onClick={() => {
							reactLocalStorage.set("viewType", "Detailed");
							setViewType("Detailed");
						}}>
						Detailed View
					</Button>
					<Button
						className='buttonColor'
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor:
								viewType === "Calendar" ? "green !important" : "transparent",
							borderRadius: "25px",
							m: 0.5,
						}}
						variant='contained'
						onClick={() => {
							reactLocalStorage.set("viewType", "Calendar");
							setViewType("Calendar");
						}}>
						Calendar View
					</Button>
				</Box>
				<Box
					sx={{
						border: "1px solid #A72C79",
						p: 0.7,
					}}>
					{viewType === "Calendar" ? (
						<Box
							sx={{
								mt: 2,

								"& .react-calendar .react-calendar__viewContainer button": {
									minHeight: {
										md: "6rem",
									},
									border: "1px solid #dad9e5",
								},
							}}>
							<Calendar
								value={selectedDate}
								onClickDay={handleDateClick}
								tileClassName={tileClassName}
								tileContent={({ date, view }) => tileContent({ date, view })}
							/>
						</Box>
					) : (
						<>
							<input
								type='date'
								value={time1}
								onChange={(e) => setTime1(e.target.value)}
							/>
							<input
								type='date'
								value={time2}
								onChange={(e) => setTime2(e.target.value)}
							/>
							<button
								onClick={() => handleearchRangeByBookingTime(time1, time2)}>
								Search
							</button>
							<button
								onClick={() => {
									setTime1("");
									setTime2("");
									setNewList(bookings);
								}}>
								Reset
							</button>
						</>
					)}
					<br />
					<button onClick={handleDownload}>Download PDF Summary</button>
					<button>
						<CSVLink
							filename={"Summary.csv"}
							data={newList || []}
							headers={headers}
							style={{
								textDecoration: "none",
								color: "black",
							}}>
							Download CSV Summary
						</CSVLink>
					</button>
				</Box>
				<Box
					sx={{
						mb: 2,
					}}>
					<Button
						className='buttonColor'
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor:
								isQuoted === "All" ? "green !important" : "transparent",
							borderRadius: "25px",
							m: 0.5,
						}}
						variant='contained'
						onClick={() => setIsQuoted("All")}>
						All
					</Button>
					<Button
						className='buttonColor'
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor:
								isQuoted === true ? "green !important" : "transparent",
							borderRadius: "25px",
							m: 0.5,
						}}
						variant='contained'
						onClick={() => setIsQuoted(true)}>
						Quoted
					</Button>
					<Button
						className='buttonColor'
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor: !isQuoted ? "green !important" : "transparent",
							borderRadius: "25px",
							m: 0.5,
						}}
						variant='contained'
						onClick={() => setIsQuoted(false)}>
						Bookings
					</Button>
					<Button
						className='buttonColor'
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor:
								isQuoted === "Pending" ? "green !important" : "transparent",
							borderRadius: "25px",
							m: 0.5,
						}}
						variant='contained'
						onClick={() => setIsQuoted("Pending")}>
						Pending
					</Button>
					<Button
						className='buttonColor'
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor:
								isQuoted === "Completed" ? "green !important" : "transparent",
							borderRadius: "25px",
							m: 0.5,
						}}
						variant='contained'
						onClick={() => setIsQuoted("Completed")}>
						Comepleted
					</Button>
					<Button
						className='buttonColor'
						sx={{
							fontWeight: "bold",
							border: "2px solid",
							backgroundColor:
								isQuoted === "Pending Quoted"
									? "green !important"
									: "transparent",
							borderRadius: "25px",
							m: 0.5,
						}}
						variant='contained'
						onClick={() => setIsQuoted("Pending Quoted")}>
						Pending Quote
					</Button>
				</Box>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'>
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							minWidth: 400,
							width: "100%",
							bgcolor: "background.paper",
							border: "2px solid #000",
							boxShadow: 24,
							p: 4,
						}}>
						{modalType === "Driver-Assign" && (
							<>
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										flexDirection: "column",
										gap: "10px",
									}}>
									<Typography
										sx={{
											textAlign: "center",
										}}
										id='modal-modal-title'
										variant='h6'
										component='h2'>
										Driver Assign Info
									</Typography>

									{(user?.userRole === "Admin" ||
										user?.userRole === "Developer") && (
											<Button
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "red",
													borderRadius: "25px",
													width: "max-content",
												}}
												variant='contained'
												onClick={() => {
													Swal.fire({
														title: "Are you sure?",
														text: "You want to assign driver to this booking?",
														icon: "warning",
														showCancelButton: true,
														confirmButtonColor: "#3085d6",
														cancelButtonColor: "#d33",
														confirmButtonText: "Yes, Assign it!",
													}).then(async (result) => {
														if (result.isConfirmed) {
															const { _id, ...newObject } = seletedBooking;
															axios
																.patch(
																	`${process.env.REACT_APP_API_PATH}/bookings/${seletedBooking?._id}`,
																	{
																		...newObject,
																	},
																	{
																		headers: {
																			Authorization: token,
																		},
																	},
																)
																.then((res) => {
																	Swal.fire({
																		icon: "success",
																		title: "Success",
																		text: res.data.message,
																	});
																	handleClose();
																})
																.catch((err) => {
																	Swal.fire({
																		icon: "error",
																		title: "Error",
																		text: err.response.data.message,
																	});
																});
														}
													});
												}}>
												Done Assign Driver
											</Button>
										)}
								</Box>
								<Box
									sx={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										gap: "10px",
										overflowX: "auto",
									}}>
									{seletedBooking?.rides?.map((rideDetails) => (
										<Box
											sx={{
												border: "1px solid #A72C79",
												p: 2,
												my: 2,
												minWidth: "300px",
											}}>
											{(user?.userRole !== "Admin" ||
												user?.userRole !== "Developer") && (
													<>
														<Typography
															gutterBottom
															variant='body'
															component='div'>
															Assigned To
														</Typography>
														<Typography
															gutterBottom
															variant='body2'
															component='div'>
															{rideDetails?.assignedTo?.email || "N/A"}
														</Typography>
														<Divider sx={{ my: 1.5 }} />
													</>
												)}
											{rideDetails?.rideType === "Hourly" && (
												<>
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														RIDE TYPE
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.rideType}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP LOCATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.picupLocation2}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														DROP-OFF LOCATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.dropOffLocation2}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														DURATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.duration2}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP DATE, TIME
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{new Date(rideDetails?.time2).toLocaleString(
															"en-GB",
														)}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
												</>
											)}
											{rideDetails?.rideType === "Distance" && (
												<>
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														RIDE TYPE
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.rideType}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP LOCATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.picupLocation1}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														DROP-OFF LOCATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.dropOffLocation1}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														EXTRA TIME
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.extraTime1}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP DATE, TIME
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{new Date(rideDetails?.time1).toLocaleString(
															"en-GB",
														)}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
												</>
											)}
											{rideDetails?.rideType === "Flat Rate" && (
												<>
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														RIDE TYPE
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.rideType}
													</Typography>
													<Divider sx={{ my: 1.5 }} />

													<Typography
														gutterBottom
														variant='body'
														component='div'>
														SERVICE TYPE
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.transferType3}
													</Typography>
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP LOCATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.picupLocation3}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														DURATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.duration3}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP DATE, TIME
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{new Date(rideDetails?.time3).toLocaleString(
															"en-GB",
														)}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
												</>
											)}
											{rideDetails?.rideType === "Chauffeur Services" && (
												<>
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														RIDE TYPE
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.rideType}
													</Typography>
													<Divider sx={{ my: 1.5 }} />

													<Typography
														gutterBottom
														variant='body'
														component='div'>
														SERVICE TYPE
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.transferType4}
													</Typography>
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP LOCATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.picupLocation4}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														DURATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.duration4}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP DATE, TIME
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{new Date(rideDetails?.time4).toLocaleString(
															"en-GB",
														)}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
												</>
											)}
											{rideDetails?.rideType === "Airport Service" && (
												<>
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														RIDE TYPE
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.rideType}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP DATE, TIME
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{new Date(rideDetails?.time5).toLocaleString(
															"en-GB",
														)}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														PICKUP LOCATION
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.picupLocation5}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														AIRPORT
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.airport}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														AIRLINE
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.airline || "N/A"}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														FLIGHT NO
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.flightNo || "N/A"}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														TERMINAL
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.terminal || "N/A"}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														FLIGHT TIME
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.flightTime || "N/A"}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														FLIGHT STATUS
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.flightStatus || "N/A"}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
													<Typography
														gutterBottom
														variant='body'
														component='div'>
														ORIGIN
													</Typography>
													<Typography
														gutterBottom
														variant='body2'
														component='div'>
														{rideDetails?.origin || "N/A"}
													</Typography>
													<Divider sx={{ my: 1.5 }} />
												</>
											)}

											{(user?.userRole === "Admin" ||
												user?.userRole === "Developer") && (
													<select
														style={{
															border: "none",
															backgroundColor: "transparent",
															fontWeight: "bold",
															color: rideDetails?.assignedTo?.email
																? "green"
																: "red",
														}}
														defaultValue={rideDetails?.assignedTo?.email}
														name='position'
														id='position'
														onChange={(e) => {
															setSelectedBooking({
																...seletedBooking,
																rides: seletedBooking?.rides?.map((ride) =>
																	ride?.rideId === rideDetails?.rideId
																		? {
																			...ride,
																			assignedTo: {
																				email: e.target.value,
																				isConfirmed: false,
																				assignedOn: new Date(),
																				confirmedOn: null,
																			},
																		}
																		: ride,
																),
															});
														}}>
														<option value=''>Select</option>
														{allDriverList?.map((driver) => (
															<option value={driver?.email}>
																{driver?.displayName}
															</option>
														))}
													</select>
												)}
										</Box>
									))}
								</Box>
							</>
						)}

						{modalType === "Paymnet-Info" && (
							<>
								<Typography
									sx={{
										textAlign: "center",
									}}
									variant='h5'>
									Payment Info
								</Typography>
								<Box
									sx={{
										display: "flex",
										flexDirection: 'column',
										justifyContent: "center",
										alignItems: "center",
										gap: 1,
										maxWidth: {
											md: "40vw",
										},
										mx: "auto",
										my: 2,
									}}>

									<Box sx={{
										display: "grid",
										gridTemplateColumns: "1fr 1fr",
										justifyContent: "center",
										alignItems: "center",
										gap: 1,
									}}>

										<div
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-start",
											}}>
											<label
												htmlFor='tax-percentage'
												style={{
													marginBottom: "5px",
													fontWeight: "bold",
													color: "#333",
												}}>
												Tax Percentage
											</label>
											<input
												id='tax-percentage'
												style={{
													padding: "10px",
													border: "1px solid",
													borderRadius: "5px",
													width: "100%",
												}}
												className='bordeColor'
												placeholder='Tax Percentage'
												value={editPayment?.tax}
												type='number'
												onChange={(e) =>
													setEditPayment({
														...editPayment,
														tax: Number(e.target.value),
													})
												}
											/>
										</div>

										<div
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "flex-start",
											}}>
											<label
												htmlFor='tips'
												style={{
													marginBottom: "5px",
													fontWeight: "bold",
													color: "#333",
												}}>
												Tips
											</label>
											<input
												id='tips'
												style={{
													padding: "10px",
													border: "1px solid",
													borderRadius: "5px",
													width: "100%",
												}}
												className='bordeColor'
												placeholder='Tips'
												value={editPayment?.tips}
												type='number'
												onChange={(e) =>
													setEditPayment({
														...editPayment,
														tips: Number(e.target.value),
													})
												}
											/>
										</div>
									</Box>
									<Button
										onClick={() => {
											axios
												.patch(
													`${process.env.REACT_APP_API_PATH}/bookings/${seletedBooking?._id}`,
													{
														...editPayment,
													},
													{
														headers: {
															Authorization: token,
														},
													},
												)
												.then((res) => {
													Swal.fire({
														icon: "success",
														title: "Success",
														text: res.data.message,
													});
													handleClose();
												})
												.catch((err) => {
													Swal.fire({
														icon: "error",
														title: "Error",
														text: err.response.data.message,
													});
												});
										}}
										className='buttonColor'
										variant='contained'>
										Update Tax/Tips
									</Button>
								</Box>


								<Typography
									sx={{
										textAlign: "center",
									}}
									variant='h6'>
									Total Amount:{" "}
									{(
										(Number(seletedBooking.priceByAdmin) ||
											Number(seletedBooking.quotedPrice) ||
											0) *
										(1 + Number(editPayment?.tax || 0) / 100) +
										Number(editPayment?.tips || 0)
									).toFixed(2)}
								</Typography>

								<Typography
									sx={{
										textAlign: "center",
									}}
									variant='h6'>
									Amount Paid:{" "}
									{seletedBooking?.payments
										?.reduce((acc, payment) => acc + Number(payment.amount), 0)
										.toFixed(2) || 0}
								</Typography>

								<Typography
									sx={{
										textAlign: "center",
									}}
									variant='h6'>
									Amount Due:{" "}
									{(
										(Number(seletedBooking.priceByAdmin) ||
											Number(seletedBooking.quotedPrice) ||
											0) *
										(1 + Number(editPayment?.tax || 0) / 100) +
										Number(editPayment?.tips || 0) -
										(seletedBooking?.payments?.reduce(
											(acc, payment) => acc + Number(payment.amount),
											0,
										) || 0)
									).toFixed(2)}
								</Typography>

								{(user?.userRole === "Admin" ||
									user?.userRole === "Developer") && (
										<Box
											sx={{
												display: "flex",
												flexDirection: {
													xs: "column",
													md: "row",
												},
												justifyContent: "center",
												alignItems: "center",
												gap: "10px",
												mt: 1,
											}}>
											<select
												name='paymentType'
												id='paymentType'
												style={{
													padding: "10px",
												}}
												onChange={(e) => setPaymentType(e.target.value)}
												value={paymentType}>
												<option value=''>Select Payment Type</option>
												<option value='Cash'>Cash</option>
												<option value='Card'>Card</option>
												<option value='Paypal'>Paypal</option>
											</select>

											<input
												style={{
													padding: "10px",
												}}
												type='text'
												name='paidFrom'
												placeholder='Paid From?'
												onChange={(e) => setPaidFrom(e.target.value)}
												value={paidFrom}
											/>

											<input
												style={{
													padding: "10px",
												}}
												type='number'
												name='amount'
												placeholder='Enter Amount'
												onChange={(e) => setAmount(e.target.value)}
												value={amount}
											/>
											<Button
												className='buttonColor'
												sx={{
													fontWeight: "bold",
													border: "2px solid",
													backgroundColor: "transparent",
													borderRadius: "25px",
													m: 0.5,
													textTransform: "capitalize",
													fontSize: "12px",
												}}
												variant='contained'
												onClick={() => {
													if (paymentType && amount && paidFrom) {
														const newPayment = {
															id: Math.round(Math.random() * 1000000),
															paymentType,
															amount,
															paidFrom,
															time: new Date(),
														};
														Swal.fire({
															title: "Are you sure?",
															text: "You want to add this payment?",
															icon: "warning",
															showCancelButton: true,
															confirmButtonColor: "#3085d6",
															cancelButtonColor: "#d33",
															confirmButtonText: "Yes, Add it!",
														}).then(async (result) => {
															if (result.isConfirmed) {
																axios
																	.patch(
																		`${process.env.REACT_APP_API_PATH}/payments/${seletedBooking?._id}`,
																		{
																			payments: [
																				...(seletedBooking?.payments || []),
																				newPayment,
																			],
																		},
																		{
																			headers: {
																				Authorization: token,
																			},
																		},
																	)
																	.then((res) => {
																		Swal.fire({
																			icon: "success",
																			title: "Success",
																			text: res.data.message,
																		});
																		handleClose();
																		setPaymentType("");
																		setAmount("");
																		setPaidFrom("");
																	})
																	.catch((err) => {
																		Swal.fire({
																			icon: "error",
																			title: "Error",
																			text: err.response.data.message,
																		});
																	});
															}
														});
													} else {
														Swal.fire({
															icon: "error",
															title: "Error",
															text: "Please fill all the fields",
														});
													}
												}}>
												Add Payment
											</Button>
										</Box>
									)}
								<TableContainer component={Paper}>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>Payment Type</TableCell>
												<TableCell>Paid From</TableCell>
												<TableCell>Amount</TableCell>
												<TableCell>Time</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{seletedBooking?.payments?.map((payment, index) => (
												<TableRow key={index}>
													<TableCell>{payment?.paymentType}</TableCell>
													<TableCell>{payment?.paidFrom}</TableCell>
													<TableCell>{payment?.amount}</TableCell>
													<TableCell>
														{new Date(payment?.time).toLocaleString()}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</>
						)}
					</Box>
				</Modal>
				{viewType === "Payment" ? (
					<Paper
						ref={divRef}
						className='container'
						sx={{ maxWidth: "500px", mx: "auto", mb: 2, minHeight: "700px" }}>
						<Table size='small' aria-label='a dense table'>
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>No</TableCell>
									<TableCell align='left'>TXN</TableCell>
									<TableCell align='left'>Date</TableCell>
									<TableCell align='left'>Paid</TableCell>
									<TableCell align='left'>Unpaid</TableCell>
									<TableCell align='center'>Price</TableCell>
								</TableRow>
							</TableHead>
							{newList?.length > 0 ? (
								<TableBody sx={{ td: { py: 1 } }}>
									{(isQuoted === true
										? newList?.filter((booking) => booking?.quoted === isQuoted)
										: isQuoted === false
											? newList?.filter((booking) => booking?.quoted !== true)
											: isQuoted === "Completed" || isQuoted === "Pending"
												? newList?.filter(
													(booking) => booking?.position === isQuoted,
												)
												: isQuoted === "Pending Quoted"
													? newList?.filter(
														(booking) =>
															booking?.position === "Quote" &&
															booking?.quoted === true &&
															booking?.quoteAccepted === false,
													)
													: newList
									).map((booking) => (
										<TableRow
											key={booking?._id}
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}>
											<TableCell align='left'>{count++}</TableCell>

											<TableCell align='left'>
												<div
													style={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}>
													{booking?.rideType === "Airport Service" && (
														<AirplanemodeActiveIcon />
													)}
													<BusinessIcon sx={{ mr: 1 }} />
													{booking?.txn}
												</div>
											</TableCell>

											<TableCell align='left'>
												{new Date(booking?.bookingTime).toLocaleDateString()}
											</TableCell>
											<TableCell align='left'>
												{booking?.payments?.reduce(
													(acc, payment) => acc + Number(payment.amount),
													0,
												) || 0}
											</TableCell>
											<TableCell align='left'>
												{(Number(booking.priceByAdmin) ||
													Number(booking.quotedPrice) ||
													0) -
													(booking?.payments?.reduce(
														(acc, payment) => acc + Number(payment.amount),
														0,
													) || 0)}
											</TableCell>
											<TableCell align='left'>
												{booking.priceByAdmin || booking.quotedPrice || 0}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							) : (
								<TableHead sx={{ th: { fontWeight: "bold" } }}>
									<TableRow>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
									</TableRow>
								</TableHead>
							)}
						</Table>
					</Paper>
				) : (
					<Paper
						className='container'
						sx={{ overflow: "auto", maxHeight: "70vh", maxWidth: "90vw" }}>
						<Table size='small' aria-label='a dense table'>
							<TableHead sx={{ th: { fontWeight: "bold" } }}>
								<TableRow>
									<TableCell align='left'>No</TableCell>
									<TableCell align='left'>Name</TableCell>
									<TableCell align='left'>TXN</TableCell>
									<TableCell align='left'>Booking Time</TableCell>
									<TableCell align='left'>Assign Info</TableCell>
									<TableCell align='left'>Quote? / Price</TableCell>
									<TableCell align='center'>Action</TableCell>
								</TableRow>
							</TableHead>
							{newList?.length > 0 ? (
								<TableBody sx={{ td: { py: 1 } }}>
									{(isQuoted === true
										? newList?.filter((booking) => booking?.quoted === isQuoted)
										: isQuoted === false
											? newList?.filter((booking) => booking?.quoted !== true)
											: isQuoted === "Completed" || isQuoted === "Pending"
												? newList?.filter(
													(booking) => booking?.position === isQuoted,
												)
												: isQuoted === "Pending Quoted"
													? newList?.filter(
														(booking) =>
															booking?.position === "Quote" &&
															booking?.quoted === true &&
															booking?.quoteAccepted === false,
													)
													: viewType === "Calendar"
														? findBookingsByDate(selectedDate)
														: newList
									).map((booking) => (
										<TableRow
											key={booking?._id}
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}>
											{booking?.position === "Completed" ? (
												<TableCell
													align='left'
													sx={{ bgcolor: "green", color: "white" }}>
													{count++}
												</TableCell>
											) : (
												<TableCell align='left'>{count++}</TableCell>
											)}
											{booking?.position === "Completed" ? (
												<TableCell
													align='left'
													sx={{ bgcolor: "green", color: "white" }}>
													{booking?.firstName + " " + booking?.lastName}
												</TableCell>
											) : (
												<TableCell align='left'>
													{booking?.firstName + " " + booking?.lastName}
												</TableCell>
											)}
											{booking?.forWho === "A Company" ? (
												<TableCell align='left'>
													<div
														style={{
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
														}}>
														{booking?.rideType === "Airport Service" && (
															<AirplanemodeActiveIcon />
														)}
														<BusinessIcon sx={{ mr: 1 }} />
														{booking?.txn}
													</div>
												</TableCell>
											) : (
												<TableCell>
													<div
														style={{
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
														}}>
														{booking?.rideType === "Airport Service" && (
															<AirplanemodeActiveIcon />
														)}
														<PersonIcon sx={{ mr: 1 }} />
														{booking?.txn}
													</div>
												</TableCell>
											)}
											<TableCell align='left'>{booking?.bookingTime}</TableCell>
											<TableCell align='left'>
												{user?.userRole === "User" ? (
													<>
														<Button
															className='buttonColor'
															sx={{
																fontWeight: "bold",
																border: "2px solid",
																backgroundColor: "transparent",
																borderRadius: "25px",
																m: 0.5,
																textTransform: "capitalize",
																fontSize: "12px",
															}}
															variant='contained'
															onClick={() => handleOpen(booking)}>
															Assign Info
														</Button>
														<Button
															className='buttonColor'
															sx={{
																fontWeight: "bold",
																border: "2px solid",
																backgroundColor:
																	(Number(booking.priceByAdmin) ||
																		Number(booking.quotedPrice) ||
																		0) -
																		(booking?.payments?.reduce(
																			(acc, payment) =>
																				acc + Number(payment.amount),
																			0,
																		) || 0) >=
																		0
																		? "yellow !important"
																		: (booking?.payments?.reduce(
																			(acc, payment) =>
																				acc + Number(payment.amount),
																			0,
																		) || 0) >=
																			(booking.priceByAdmin ||
																				booking.quotedPrice ||
																				0)
																			? "green !important"
																			: "black !important",
																borderRadius: "25px",
																m: 0.5,
																textTransform: "capitalize",
																fontSize: "12px",
															}}
															variant='contained'
															onClick={() => {
																handleOpen(booking);

																setModalType("Paymnet-Info");
															}}>
															Paymnet Info
														</Button>
													</>
												) : (
													<>
														<Button
															className='buttonColor'
															sx={{
																fontWeight: "bold",
																border: "2px solid",
																backgroundColor: "transparent",
																borderRadius: "25px",
																m: 0.5,
																textTransform: "capitalize",
																fontSize: "12px",
															}}
															variant='contained'
															onClick={() => {
																handleOpen(booking);
																setModalType("Driver-Assign");
															}}>
															Assign Driver
														</Button>
														<Button
															className='buttonColor'
															sx={{
																fontWeight: "bold",
																border: "2px solid",
																backgroundColor:
																	(booking?.payments?.reduce(
																		(acc, payment) =>
																			acc + Number(payment.amount),
																		0,
																	) || 0) === 0
																		? "black !important"
																		: (booking?.payments?.reduce(
																			(acc, payment) =>
																				acc + Number(payment.amount),
																			0,
																		) || 0) >=
																			(booking.priceByAdmin ||
																				booking.quotedPrice ||
																				0)
																			? "green !important"
																			: "#cfd156 !important",
																borderRadius: "25px",
																m: 0.5,
																textTransform: "capitalize",
																fontSize: "12px",
															}}
															variant='contained'
															onClick={() => {
																handleOpen(booking);
																setModalType("Paymnet-Info");
															}}>
															Paymnet Info
														</Button>
													</>
												)}
											</TableCell>

											<TableCell align='left'>
												{booking?.quoted ? (
													<>
														{(user?.userRole === "Admin" ||
															user?.userRole === "Developer") &&
															booking?.position === "Quote" ? (
															<h4>
																<span
																	style={{
																		color: "red",
																	}}>
																	Set Quote
																</span>
																<QuoteEdit
																	booking={booking}
																	setSubmitting={setSubmitting}
																	token={token}
																/>
															</h4>
														) : (
															<>
																<h4>${booking?.quotedPrice}</h4>
																{(user?.userRole === "Admin" ||
																	user?.userRole === "Developer") &&
																	booking?.quoteAccepted && (
																		<h4
																			style={{
																				color: booking?.quoteAccepted
																					? "green"
																					: "red",
																			}}>
																			{booking?.position === "Completed" ? (
																				"Trip Completed"
																			) : (
																				<>
																					Quote{" "}
																					{booking?.quoteAccepted
																						? "Accepted"
																						: "Rejected"}{" "}
																					by user
																				</>
																			)}
																		</h4>
																	)}
															</>
														)}
													</>
												) : (
													<h4>
														{user?.userRole === "Admin" ||
															user?.userRole === "Developer" ? (
															<>
																<span
																	style={{
																		color: "red",
																	}}>
																	Set Price
																</span>
																<PriceEdit
																	booking={booking}
																	setSubmitting={setSubmitting}
																	token={token}
																/>
															</>
														) : (
															booking?.priceByAdmin || booking?.quotedPrice
														)}
													</h4>
												)}
											</TableCell>
											<TableCell align='center'>
												<Box
													sx={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
													}}>
													<Link
														to={
															user?.userRole === "Admin" ||
																user?.userRole === "Developer"
																? `/dashboard/bookings/${booking?._id}`
																: `/userDashboard/bookings/${booking?._id}`
														}
														style={{ textDecoration: "none" }}>
														<Button
															className='buttonColor'
															sx={{
																fontWeight: "bold",
																border: "2px solid",
																backgroundColor: "transparent",
																borderRadius: "25px",
																m: 0.5,
															}}
															variant='contained'>
															<RemoveRedEyeIcon fontSize='small' />
														</Button>
													</Link>
													<Button
														className='buttonColor'
														sx={{
															fontWeight: "bold",
															border: "2px solid",
															backgroundColor: "transparent",
															borderRadius: "25px",
															m: 0.5,
														}}
														variant='contained'
														onClick={() => handlePreview(booking)}>
														<PrintIcon fontSize='small' />
													</Button>

													{(user?.userRole === "Admin" ||
														user?.userRole === "Developer") && (
															<>
																<Button
																	className='buttonColor'
																	onClick={() => handleDelete(booking?._id)}
																	sx={{
																		fontWeight: "bold",
																		border: "2px solid",
																		backgroundColor: "transparent",
																		borderRadius: "25px",
																		m: 0.5,
																	}}
																	variant='contained'>
																	<DeleteIcon fontSize='small' />
																</Button>
																{booking?.position === "Completed" ? (
																	<></>
																) : (
																	<>
																		{(booking?.quoted &&
																			booking?.quoteAccepted) ||
																			booking?.position === "Pending" ? (
																			<Button
																				className='buttonColor'
																				onClick={() => handleDone(booking?._id)}
																				sx={{
																					fontWeight: "bold",
																					border: "2px solid",
																					backgroundColor: "transparent",
																					borderRadius: "25px",
																					m: 0.5,
																				}}
																				variant='contained'>
																				<DoneOutlineIcon fontSize='small' />
																			</Button>
																		) : (
																			<> </>
																		)}
																	</>
																)}
															</>
														)}
												</Box>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							) : (
								<TableHead sx={{ th: { fontWeight: "bold" } }}>
									<TableRow>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
										<TableCell align='left'>N/A</TableCell>
									</TableRow>
								</TableHead>
							)}
						</Table>
					</Paper>
				)}
			</Grid>
			{!bookings && <BeatLoader size={10} />}
			<Backdrop
				sx={{
					color: "#fff",
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={!bookings || submitting}>
				<CircularProgress color='inherit' />
			</Backdrop>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={showPreview}
				onClose={handleClosePreview}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}>
				<Fade in={showPreview}>
					<Box sx={style}>
						<QuotationPreview
							show={showPreview}
							onClose={handleClosePreview}
							data={showPreview}
							token={token}
							isAdmin={
								user?.userRole === "Admin" || user?.userRole === "Developer"
									? true
									: false
							}
						/>
					</Box>
				</Fade>
			</Modal>
		</Container>
	);
};

export default Bookings;

const QuoteEdit = ({ booking, setSubmitting, token }) => {
	const [price, setPrice] = useState(booking?.quotedPrice);
	return (
		<Box
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}>
			<input
				placeholder='Quoted Price'
				defaultValue={booking?.quotedPrice}
				type='text'
				style={{
					width: "100px",
				}}
				onChange={(e) => setPrice(e.target.value)}
			/>
			<button
				style={{
					backgroundColor: "transparent",
					cursor: "pointer",
					background: "green",
					color: "white",
					border: "1px solid black",
					borderRadius: "5px",
					marginLeft: "5px",
					width: "90px",
				}}
				onClick={() => {
					const id = booking?._id;

					Swal.fire({
						title: "Are you sure?",
						text: "You want to quote this booking and send email to user?",
						icon: "warning",
						showCancelButton: true,
						confirmButtonColor: "#3085d6",
						cancelButtonColor: "#d33",
						confirmButtonText: "Yes, Quote it!",
					}).then(async (result) => {
						if (result.isConfirmed) {
							setSubmitting(true);
							try {
								const response = await axios.patch(
									`${process.env.REACT_APP_API_PATH}/quotedPrice/${id}`,
									{
										...booking,
										quotedPrice: price,
									},
									{
										headers: {
											Authorization: token,
										},
									},
								);
								if (response.status) {
									Swal.fire({
										icon: "success",
										title: "Success",
										text: "Quoted Successfully & Email Sent",
									});
									setSubmitting(false);
								}
							} catch (error) {
								Swal.fire({
									icon: "error",
									title: "Error",
									text: error.response.data.message,
								});
							}
						}
					});
				}}>
				Set Quote & Send Email
			</button>
		</Box>
	);
};
const PriceEdit = ({ booking, setSubmitting, token }) => {
	const [price, setPrice] = useState(booking?.priceByAdmin);
	return (
		<Box
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}>
			<input
				placeholder='Set Price'
				defaultValue={booking?.priceByAdmin}
				type='text'
				style={{
					width: "100px",
				}}
				onChange={(e) => setPrice(e.target.value)}
			/>
			<button
				style={{
					backgroundColor: "transparent",
					fontWeight: "bold",
					cursor: "pointer",
					background: "green",
					color: "white",
					border: "1px solid black",
					borderRadius: "5px",
					marginLeft: "5px",
				}}
				onClick={() => {
					const id = booking?._id;

					Swal.fire({
						title: "Are you sure?",
						text: "You want to set price for this booking",
						icon: "warning",
						showCancelButton: true,
						confirmButtonColor: "#3085d6",
						cancelButtonColor: "#d33",
						confirmButtonText: "Yes, Set it!",
					}).then(async (result) => {
						if (result.isConfirmed) {
							setSubmitting(true);
							try {
								const response = await axios.patch(
									`${process.env.REACT_APP_API_PATH}/priceByAdmin/${id}`,
									{
										priceByAdmin: price,
									},
									{
										headers: {
											Authorization: token,
										},
									},
								);
								if (response.status) {
									Swal.fire({
										icon: "success",
										title: "Success",
										text: "Price set Successfully",
									});
									setSubmitting(false);
								}
							} catch (error) {
								Swal.fire({
									icon: "error",
									title: "Error",
									text: error.response.data.message,
								});
							}
						}
					});
				}}>
				Save
			</button>
		</Box>
	);
};
